
import VueReadMoreSmooth from 'vue-read-more-smooth';
import moment from 'moment';
import autolink from '../../mixins/autolink';
import debounce from '../../mixins/debounce';
import ContactName from '../contacts/contact-name';
import events from '../../mixins/events';
import modals from '../../mixins/modals';
import GmapsAddress from '../gmaps-address';
import SaveButton from '../save-button';
import ConfirmModal from '../confirm-modal.vue';
import ExpandableText from '../helpers/expandable-text.vue';
import EmailDropdown from '../contacts/email-dropdown.vue';
import EditEventModal from './edit-event-modal';
import EventConfirmModal from './event-confirm-modal';
import EventAttestationFormModal from './event-attestation-form-modal';
import ConnectToSessionsModal from './sessions/connect-to-sessions-modal.vue';
import EventLuciteModal from './event-lucite-modal.vue';
import CompanyFeedbackModal from './company-feedback-modal.vue';
import EventExpensesModal from './event-expenses-modal.vue';

export default {
    name: 'EventDetailsCard',
    components: {
        EventExpensesModal,
        CompanyFeedbackModal,
        EventLuciteModal,
        ConnectToSessionsModal,
        EmailDropdown,
        ExpandableText,
        ConfirmModal,
        EventAttestationFormModal,
        ContactName,
        EditEventModal,
        GmapsAddress,
        SaveButton,
        EventConfirmModal,
        VueReadMoreSmooth,
    },
    mixins: [events, modals, autolink, debounce],
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            addingCompanies: false,
            addingContacts: false,
            companiesAddingInputIsShown: false,
            contactsAddingInputIsShown: false,
            companiesToAdd: [],
            contactsToAdd: [],
            eventHasCompaniesWorkingCopy: structuredClone(this.event.eventHasCompanies),
            loading: false,
            modal: null,
            showModal: false,
            toConfirm: true,
            toCancel: true,
            eventToConfirm: null,
            followUpdating: false,
        };
    },
    computed: {
        startDate() {
            if (this.event.start) {
                return this.formatDate(this.event.start);
            } else {
                return null;
            }
        },
        endDate() {
            if (this.event.end) {
                return this.formatDate(this.event.end);
            } else {
                return null;
            }
        },
        downloadUrl() {
            return this.$axios.defaults.baseURL + '/api/events/excel_export/' + this.event.id;
        },
        attestationFormUrl() {
            return this.$axios.defaults.baseURL + '/api/events/attestation_form/' + this.event.id;
        },
        companiesSelectUrl() {
            if (!this.event.start || !this.event.end) {
                return '/api/companies/for_select';
            }
            return `/api/companies/for_select?restrictionStartDate=${this.event.start}&restrictionEndDate=${this.event.end}`;
        },
        contactsSelectUrl() {
            return `/api/contacts/for_select?company=${this.eventHasCompaniesWorkingCopy
                .map((ehc) => ehc.company.id)
                .join(',')}`;
        },
        lastSyncInfo() {
            if (!this.event.sessionsId) {
                return '';
            }

            const userTimezone = moment.tz.guess();
            const lastInfoSync = this.event.sessionsLastInfoSync
                ? this.formatDateTime(this.event.sessionsLastInfoSync, userTimezone)
                : 'n/a';
            const lastClientsSync = this.event.sessionsLastClientsSync
                ? this.formatDateTime(this.event.sessionsLastClientsSync, userTimezone)
                : 'n/a';
            const lastMeetingRequestsSync = this.event.sessionsLastMeetingRequestsSync
                ? this.formatDateTime(this.event.sessionsLastMeetingRequestsSync, userTimezone)
                : 'n/a';

            // eslint-disable-next-line max-len
            return `Last event details sync: ${lastInfoSync}; Last clients sync: ${lastClientsSync}; Last meeting requests sync: ${lastMeetingRequestsSync}`;
        },
        lastSync() {
            if (
                !this.event.sessionsId ||
                !this.event.sessionsLastInfoSync ||
                !this.event.sessionsLastClientsSync ||
                !this.event.sessionsLastMeetingRequestsSync
            ) {
                return null;
            }

            return moment.min(
                moment(this.event.sessionsLastInfoSync),
                moment(this.event.sessionsLastClientsSync),
                moment(this.event.sessionsLastMeetingRequestsSync)
            );
        },
        lastSyncClass() {
            if (!this.lastSync) {
                return 'bg-warning';
            }

            const diff = moment().diff(this.lastSync, 'hours');

            if (diff < 24) return 'bg-success';
            if (diff > 48) return 'bg-danger';
            return 'bg-warning';
        },
        lastSyncMsg() {
            if (!this.lastSync) {
                return 'never';
            }

            return this.lastSync.fromNow();
        },
    },
    watch: {
        companiesToAdd: {
            handler(newCompanies, oldCompanies) {
                const oldCompanyIds = oldCompanies.map((x) => x.id);
                const addedCompanies = newCompanies.filter((x) => !oldCompanyIds.includes(x.id));

                for (const addedCompany of addedCompanies) {
                    if (addedCompany.isRestricted) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: 'warning',
                            title: 'Restricted Company',
                            message:
                                `The company ${addedCompany.name} you selected is on the restricted list` +
                                (this.event.start && this.event.end ? ' during the event.' : '.'),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        openEventluciteModal(eventId) {
            this.isLoading = true;
            this.eventId = eventId;
            this.$nextTick(() => {
                this.openModal(this.$refs.eventLuciteModal);
            });
            this.isLoading = false;
        },
        addCompanies() {
            const alreadyAddedCompaniesIdsMap = {};
            const promises = [];
            let addedCompaniesCount = 0;
            let skippedCompaniesCount = 0;
            let combinedPromise;
            for (const eventHasCompany of this.eventHasCompaniesWorkingCopy) {
                alreadyAddedCompaniesIdsMap[eventHasCompany.company['@id']] = true;
            }
            for (const companyToAdd of this.companiesToAdd) {
                if (companyToAdd['@id'] in alreadyAddedCompaniesIdsMap) {
                    skippedCompaniesCount++;
                    continue;
                }
                this.addingCompanies = true;
                ((closureCompany) => {
                    promises.push(
                        this.$axios
                            .post('/api/event_has_companies', {
                                company: closureCompany['@id'],
                                event: this.event['@id'],
                            })
                            .then((response) => {
                                this.eventHasCompaniesWorkingCopy.push({
                                    ...response.data,
                                    company: closureCompany,
                                });
                            })
                    );
                })(companyToAdd);
                addedCompaniesCount++;
            }
            const toastId = this.generateUUID();
            if (promises.length === 0) {
                this.addToast({
                    type: 'warning',
                    title: 'Already added',
                    message: 'Selected companies are already added to the event',
                    id: toastId,
                });
                combinedPromise = Promise.resolve();
            } else {
                let firstMessagePart = '';
                if (addedCompaniesCount === 1) {
                    firstMessagePart = `1 company has been added to the event`;
                } else {
                    firstMessagePart = `${addedCompaniesCount} companies have been added to the event`;
                }
                combinedPromise = Promise.all(promises).then(() => {
                    this.addToast({
                        type: 'success',
                        title: 'Added successfully',
                        message:
                            firstMessagePart +
                            (skippedCompaniesCount > 0
                                ? `, ${skippedCompaniesCount} were skipped because they are already added`
                                : ''),
                        id: toastId,
                    });
                    this.$emit('companies-added');
                });
            }
            combinedPromise.then(() => {
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.addingCompanies = false;
                this.companiesToAdd = [];
                this.hideCompaniesAddingInput();
            });
        },
        addContacts() {
            const alreadyAddedContactsIdsMap = {};
            const promises = [];
            let addedContactsCount = 0;
            let skippedContactsCount = 0;
            let combinedPromise;
            for (const eventHasCompanyContact of this.event.eventHasCompanyContacts) {
                alreadyAddedContactsIdsMap[eventHasCompanyContact.contact['@id']] = true;
            }
            for (const contactToAdd of this.contactsToAdd) {
                if (contactToAdd['@id'] in alreadyAddedContactsIdsMap) {
                    skippedContactsCount++;
                    continue;
                }
                this.addingContacts = true;
                ((closureContact) => {
                    promises.push(
                        this.$axios.post('/api/event_has_company_contacts', {
                            contact: closureContact['@id'],
                            event: this.event['@id'],
                        })
                    );
                })(contactToAdd);
                addedContactsCount++;
            }
            const toastId = this.generateUUID();
            if (promises.length === 0) {
                this.addToast({
                    type: 'warning',
                    title: 'Already added',
                    message: 'Selected contacts are already added to the event',
                    id: toastId,
                });
                combinedPromise = Promise.resolve();
            } else {
                let firstMessagePart = '';
                if (addedContactsCount === 1) {
                    firstMessagePart = `1 contact has been added to the event`;
                } else {
                    firstMessagePart = `${addedContactsCount} contacts have been added to the event`;
                }
                combinedPromise = Promise.all(promises).then(() => {
                    this.addToast({
                        type: 'success',
                        title: 'Added successfully',
                        message:
                            firstMessagePart +
                            (skippedContactsCount > 0
                                ? `, ${skippedContactsCount} were skipped because they are already added`
                                : ''),
                        id: toastId,
                    });
                    this.$emit('event-updated');
                });
            }
            combinedPromise.then(() => {
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.addingContacts = false;
                this.contactsToAdd = [];
                this.hideCompanyContactsAddingInput();
            });
        },
        editEvent() {
            this.showModal = true;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.editEventModal);
            });
        },
        editExpenses() {
            this.showModal = true;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.eventExpensesModal);
            });
        },
        eventUpdated() {
            this.showModal = false;
            this.closeModal(this.modal);
            this.$emit('event-updated');
        },
        hideCompaniesAddingInput() {
            this.companiesAddingInputIsShown = false;
        },
        hideCompanyContactsAddingInput() {
            this.contactsAddingInputIsShown = false;
        },
        showCompaniesAddingInput() {
            this.companiesAddingInputIsShown = true;
        },
        showCompanyContactsAddingInput() {
            this.contactsAddingInputIsShown = true;
        },
        downloadEvent() {
            window.open(this.downloadUrl, '_blank').focus();
        },
        downloadAttestationForm() {
            window.open(this.attestationFormUrl, '_blank').focus();
        },
        openEditAttestationForm() {
            this.modal = this.openModal(this.$refs.eventAttestationFormModal);
        },
        closeAttestationModal() {
            this.closeModal(this.modal);
            this.$emit('event-updated');
        },
        async confirmOrRejectEvent(toConfirm, toCancel) {
            this.toConfirm = toConfirm;
            this.toCancel = toCancel;
            this.eventToConfirm = this.event;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.eventToConfirm);
        },
        toggleFollow(follows) {
            this.followUpdating = true;
            this.$axios
                .post(`/api/events/${this.event.id}/toggle_follow`, {
                    follows,
                })
                .then((response) => {
                    const newEvent = response.data;
                    newEvent['@id'] = '/api/events/' + newEvent.id;
                    this.$emit('update-event', newEvent);
                })
                .finally(() => {
                    this.followUpdating = false;
                });
        },
        deleteEvent() {
            if (this.event.hasInteractions) {
                alert('To remove this event, first manually delete the interactions associated with it.');
            } else {
                this.modal = this.openModal(this.$refs.deleteEventModal);
            }
        },
        doDeleteEvent() {
            if (this.event.hasInteractions) {
                alert('To remove this event, first manually delete the interactions associated with it.');
            } else {
                this.$axios.delete(`/api/events/${this.event.id}`).finally(() => {
                    this.closeModal(this.modal);
                    this.modal = null;
                    this.$router.push('/events');
                });
            }
        },
        cancelEventDeletion() {
            this.closeModal(this.modal);
            this.modal = null;
        },
        setFeedbackStatus(status) {
            this.$axios
                .patch(
                    `/api/event/${this.event.id}/feedback_status`,
                    {
                        id: this.event.id,
                        feedbackStatus: status,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    }
                )
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Feedback Status Updated',
                        message: 'The feedback status has been changed to ' + status,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.$emit('event-updated', response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setIsCompleted() {
            // eslint-disable-next-line max-len
            if (
                !confirm(
                    'Are you sure you want to mark this event as completed? Once confirmed, no further actions, such as the submission of a Feedback Memorandum, will be required.'
                )
            ) {
                return;
            }

            this.$axios
                .patch(
                    `/api/event/${this.event.id}/is_completed`,
                    {
                        id: this.event.id,
                        isCompleted: true,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    }
                )
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Event Completed Updated',
                        message: 'The event has been set to completed',
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.$emit('event-updated', response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openConnectToSessionsModal() {
            this.modal = this.openModal(this.$refs.connectToSessionsModal);
        },
        async showEventNotesSummaryModal() {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            await this.$nextTick();
            this.$refs.companyFeedbackModal.resetWorkingCopy();
            this.modal = this.openModal(this.$refs.companyFeedbackModal);
        },
        syncSessions() {
            this.$axios.post(`/api/events/${this.event.id}/sync_sessions`).then((response) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Sessions Sync',
                    message: response.data.message,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            });
        },
    },
};
