import { render, staticRenderFns } from "./edit-event-modal.vue?vue&type=template&id=6bece64c&scoped=true"
import script from "./edit-event-modal.vue?vue&type=script&lang=js"
export * from "./edit-event-modal.vue?vue&type=script&lang=js"
import style0 from "./edit-event-modal.vue?vue&type=style&index=0&id=6bece64c&prod&lang=scss"
import style1 from "./edit-event-modal.vue?vue&type=style&index=1&id=6bece64c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bece64c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HintText: require('/codebuild/output/src3357764168/src/web/components/hint-text.vue').default,SaveButton: require('/codebuild/output/src3357764168/src/web/components/save-button.vue').default})
