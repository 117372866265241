
import TableFilters from "../table-filters";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import modals from '../../mixins/modals';

export default {
    name: "RecipientDetailsModal",
    components: { QuintableSearchInput, TableFilters},
    mixins: [modals],
    props: {
        emailId: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            tableUpdated: this.generateUUID(),
            status: null,
            emailStatistics: null,
            mode: "filtered",
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowData: [],
            ajaxRows: null,
            pageChanged: false,
            searchQuery: null,
        };
    },
    computed: {
        config() {
            return {
                pagination: 20,
                ajaxUrl: `/api/emails/recipients/${this.emailId}`,
                search: true,
                columns: [
                    {headline: "Name", sort: true},
                    {headline: "Email", sort: true, breakpoint: 'lg'},
                    {headline: "Company", sort: true, breakpoint: 'xl'},
                    {headline: "Excluded from Emails", sort: true, breakpoint: "lg"},
                    {headline: "Active", sort: true, breakpoint: "md"},
                    {headline: "Temporary", sort: true, breakpoint: 'md'},
                    {headline: "Sent", sort: true},
                    {headline: "Read", sort: true},
                ],
                select: true,
                selectAll: true,
                prettySelect: true,
                selectPosition: "pre",
            }
        },
        filters() {
            return {
                status: this.status,
            };
        },
        emailReadPercentage() {
            if (this.emailStatistics === null || this.emailStatistics.toRecipients === 0) {
                return null;
            }

            return Math.floor(this.emailStatistics.readToRecipients / this.emailStatistics.toRecipients * 100);
        },
    },
    watch: {
        emailId() {
            this.loadEmailStatistics();
        },
        filters() {
            this.pageChanged = true;
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowData.includes(rows[i].selector)) {
                        this.preSelectedRowData.push(rows[i].selector);
                    }
                }
                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].selector;

                    const index = this.preSelectedRowData.indexOf(id);

                    if (!rows.map(r => r.selector).includes(id) && index !== -1) {
                        this.preSelectedRowData.splice(index, 1);
                    }
                }
            }
        },
    },
    mounted() {
        this.loadEmailStatistics();
    },
    methods: {
        onRowsUpdated(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowData.map(s => {
                    return {
                        key: "selector",
                        value: s
                    }
                });
            }
            this.ajaxRows = data.rows;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        updateSearchQuery(searchQuery) {
            this.pageChanged = true;
            this.searchQuery = searchQuery;
        },
        clearPreSelection() {
            this.preSelectedRowData = [];
            this.preSelectedRows = [];
        },
        openBulkOperationModal(mode) {
            // update contact filters in parent modal
            this.$emit("filter-update",
                mode === "selected" ?
                { ids: this.preSelectedRowData } :
                {
                    emailRecipientFilter: {
                        emailId: this.emailId,
                        status: this.status,
                        search: this.searchQuery,
                    }
                }
            );
            this.$emit("open-bulk-modal");
        },
        loadEmailStatistics() {
            this.$axios.get(`/api/emails/recipient_statistics/${this.emailId}`).then(response => {
                this.emailStatistics = response.data;
            });
        },
        resetFilters() {
            this.tableUpdated = this.generateUUID();
            this.status = null;
        },
        download() {
            window.open(`/api/emails/recipients/export/${this.emailId}`, '_blank').focus();
        }
    },
}
