
import moment from 'moment';
import SaveButton from '../save-button.vue';

export default {
    name: 'EventExpensesModal',
    components: { SaveButton },
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            eventExpensesList: [
                {
                    eventExpenseType: null,
                    amount: null,
                    date: null,
                },
            ],
            expenseTypes: [],
            combinedTypes: [],
            eventWorkingCopy: {},
            showMessage: false,
        };
    },

    computed: {
        groupedTypes() {
            return this.combinedTypes.reduce((acc, item) => {
                if (!acc[item.group]) {
                    acc[item.group] = [];
                }
                acc[item.group].push(item);
                return acc;
            }, {});
        },
    },

    mounted() {
        this.$axios.get('/api/events/options').then((response) => {
            this.combinedTypes = [
                ...response.data.expenseTypes.map((et) => ({
                    label: et.expenseTypeTitle,
                    value: et['@id'] || `/api/event_expense_types/${et.id}`, // Use the API @id if available
                    group: 'Expenses',
                })),
                ...response.data.revenueTypes.map((rt) => ({
                    label: rt.expenseTypeTitle,
                    value: rt['@id'] || `/api/event_expense_types/${rt.id}`,
                    group: 'Revenues',
                })),
            ];

            this.eventWorkingCopy = JSON.parse(JSON.stringify(this.event));
            // Create a list of only expenses
            this.eventExpensesList = (this.eventWorkingCopy.eventExpenses || [])
                .filter((item) => item.eventExpenseType)
                .map((expense) => {
                    if (expense.eventExpenseType && expense.eventExpenseType['@id']) {
                        const found = this.combinedTypes.find((ct) => ct.value === expense.eventExpenseType['@id']);
                        if (found) {
                            expense.eventExpenseType = found;
                        }
                    }
                    return expense;
                });
        });
    },
    methods: {
        handleSelectChange() {
            this.showMessage = false;
        },
        normalizeAmount(input) {
            if (typeof input !== 'string') input = String(input); // Ensure input is a string

            // Step 1: Remove spaces and invalid characters except numbers, dots, and commas
            input = input.replace(/[^\d.,-]/g, '');

            // Step 2: Identify whether the input uses ',' or '.' as the decimal separator
            const lastComma = input.lastIndexOf(',');
            const lastDot = input.lastIndexOf('.');

            if (lastComma > lastDot) {
                // If the last ',' appears after the last '.', assume ',' is the decimal separator
                input = input.replace(/\./g, ''); // Remove all thousand separators (.)
                input = input.replace(/,/, '.'); // Convert last ',' to '.'
            } else {
                // Otherwise, assume '.' is the decimal separator and remove ',' as a thousand separators
                input = input.replace(/,/g, '');
            }

            // Step 3: Convert to number and ensure two decimal places
            const number = parseFloat(input);
            return isNaN(number) ? null : number.toFixed(2); // Return as a string with 2 decimal places
        },

        addExpense() {
            this.eventExpensesList.push({
                eventExpenseType: null,
                amount: null,
            });
        },
        removeExpense(index) {
            this.eventExpensesList.splice(index, 1);
        },

        submit() {
            this.loading = true;

            try {
                const finalEvent = {
                    eventExpenses: [
                        ...this.eventExpensesList.map((expense) => ({
                            eventExpenseType: expense.eventExpenseType['@id']
                                ? expense.eventExpenseType['@id']
                                : expense.eventExpenseType.value,
                            amount: parseFloat(this.normalizeAmount(expense.amount)).toString(),
                            event: this.eventWorkingCopy['@id'],
                            date:
                                (expense.date &&
                                    expense.date !== '0000-00-00' &&
                                    !`${expense.date}`.startsWith('-') &&
                                    moment(expense.date).format('YYYY-MM-DD')) ||
                                null,
                        })),
                    ],
                };

                this.$axios
                    .patch(`/api/events/${this.eventWorkingCopy.id}`, finalEvent, {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    })
                    .then((response) => {
                        this.$emit('event-updated', response.data);
                        this.$emit('event-updated');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                this.showMessage = true;
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }
        },
    },
};
