

export default {
    name: "ViewEmailModal",
    props: {
        emailId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            email: {
                subject: null,
                body: null,
            },
        }
    },
    computed: { },
    watch: {
        emailId(id) {
            if (id) this.loadEmail();
        },
    },
    methods: {
        loadEmail() {
            this.email = {
                subject: null,
                body: null,
            };
            this.$axios.get(`/api/emails/minimal/${this.emailId}`).then((response) => {
                this.email = response.data;
            })
        },
    },
}
