
import moment from 'moment';
import HintText from '../hint-text.vue';
import interactionConflicts from '../../mixins/interaction-conflicts';
import ConflictsWarning from '../interactions/conflicts-warning.vue';

export default {
    name: 'EventMeeting',
    components: { ConflictsWarning, HintText },
    mixins: [interactionConflicts],
    props: {
        value: {
            type: Object,
            required: true,
        },
        interactionTypes: {
            type: Array,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            selectedCompanies: [],
            selectedContacts: [],
            highlightFieldStart: false,
            highlightFieldEnd: false,
            isBlocker: false,
            changedTime: false,
            suggestedSubject: null,
        };
    },
    computed: {
        event() {
            return this.value.event;
        },
        start: {
            get() {
                if (this.value.start) {
                    return moment.tz(this.value.start, this.timezone).locale('en-US').format('YYYY-MM-DD HH:mm');
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$emit('input', { ...this.value, start: newValue });

                if (newValue) {
                    this.$nextTick(() => {
                        const endDateIsInvalid = !this.end || this.end === 'Invalid date';

                        if (endDateIsInvalid || !this.changedTime) {
                            this.end = moment(newValue).add(30, 'm').format('YYYY-MM-DD HH:mm');
                            this.highlightChangedFieldEnd();

                            this.changedTime = true;
                        }
                    });
                }
            },
        },
        end: {
            get() {
                if (this.value.end) {
                    return moment.tz(this.value.end, this.timezone).locale('en-US').format('YYYY-MM-DD HH:mm');
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$emit('input', { ...this.value, end: newValue });

                if (newValue) {
                    this.$nextTick(() => {
                        const startDateIsInvalid = !this.start || this.start === 'Invalid date';

                        if (startDateIsInvalid || !this.changedTime) {
                            this.start = moment(newValue).subtract(30, 'm').format('YYYY-MM-DD HH:mm');
                            this.highlightChangedFieldStart();

                            this.changedTime = true;
                        }
                    });
                }
            },
        },
        interactionType: {
            get() {
                return this.value.interactionType;
            },
            set(newValue) {
                this.$emit('input', { ...this.value, interactionType: newValue });
            },
        },
        subject: {
            get() {
                return this.value.subject;
            },
            set(newValue) {
                this.$emit('input', { ...this.value, subject: newValue });
            },
        },
        meetingPoint: {
            get() {
                return this.value.meetingPoint;
            },
            set(newValue) {
                this.$emit('input', { ...this.value, meetingPoint: newValue });
            },
        },
        focus: {
            get() {
                return this.value.focus;
            },
            set(newValue) {
                this.$emit('input', { ...this.value, focus: newValue });
            },
        },
        timezone: {
            get() {
                return this.value.timezone;
            },
        },
    },
    watch: {
        selectedContacts: {
            handler() {
                this.$emit('input', { ...this.value, selectedContacts: this.selectedContacts });
                this.makeSubjectSuggestion();
            },
        },
        selectedCompanies: {
            handler() {
                this.$emit('input', { ...this.value, selectedCompanies: this.selectedCompanies });
                this.makeSubjectSuggestion();
            },
        },
        interactionType: {
            handler() {
                const type = this.fullInteractionType();
                this.isBlocker = type && type.title === 'Blocker';
                this.makeSubjectSuggestion();
            },
            deep: true,
        },
    },
    created() {
        if (this.value.selectedContacts && this.value.selectedContacts.length > 0) {
            this.selectedContacts = this.value.selectedContacts;
        } else if (this.value.interactionParticipants && this.value.interactionParticipants.length > 0) {
            this.selectedContacts = this.value.interactionParticipants.map(
                (interactionParticipant) => interactionParticipant.contact
            );
        } else {
            this.selectedContacts = [];
        }
    },
    methods: {
        timeZoneAbbr(start, timezone) {
            return moment.tz(start, timezone).zoneAbbr();
        },
        async highlightChangedFieldStart() {
            this.highlightFieldStart = true;
            // Adjust the timeout duration (2000ms = 2 seconds) to control how long the highlight lasts.
            await new Promise((resolve) => setTimeout(resolve, 5000));
            this.highlightFieldStart = false;
        },
        async highlightChangedFieldEnd() {
            this.highlightFieldEnd = true;
            // Adjust the timeout duration (2000ms = 2 seconds) to control how long the highlight lasts.
            await new Promise((resolve) => setTimeout(resolve, 5000));
            this.highlightFieldEnd = false;
        },
        fullInteractionType() {
            if (typeof this.interactionType === 'string') {
                return this.interactionTypes.find((x) => x['@id'] === this.interactionType) || null;
            }
            return this.interactionType || null;
        },
        makeSubjectSuggestion() {
            if (this.interactionType && (this.selectedCompanies.length > 0 || this.selectedContacts.length > 0)) {
                const interactionTitle = this.fullInteractionType()?.title || 'Meeting';
                const contact = this.selectedContacts[0];
                const companyName = contact?.company?.name;
                const contactName = contact?.name;

                this.suggestedSubject =
                    interactionTitle +
                    ' with ' +
                    (this.selectedContacts.length > 0
                        ? (companyName ? companyName + ': ' : '') + (contactName || 'Unknown Contact')
                        : this.selectedCompanies[0].name);
            } else if (this.selectedCompanies.length === 0 && this.selectedContacts.length === 0) {
                this.suggestedSubject = null;
            }
        },
        applyPlaceholderToSubject() {
            if (this.suggestedSubject) {
                this.subject = this.suggestedSubject;
            }
        },
    },
};
